import React from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql }  from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div/Div.styles'
import Hr from '../components/Hr'
import Button from './../components/Button'
import H4 from '../components/H4'
import H6 from '../components/H6'
import Body2 from '../components/Body2'

import { Container } from '../components/styles/Layout.styles'
import { 
  styledCareers,
  header,
  ourPillars
} from '../components/styles/Careers.styles'

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <section css={styledCareers}>
      <Container>
        <Row
          css={header}
          mb={[50, 70]}
          justifyContent={['left', 'center']}
        >
          <Column>
            <H4>Vagas abertas</H4>
          </Column>
        </Row>
        <Row
          css={ourPillars}
          justifyContent={['left', 'center']}
          flexDirection={['column', 'column', 'row', 'row']}
        >
          <Div
            maxWidth={['100%', 422, 328, 328]}
            style={{
              marginBottom: 24
            }}
          >
            <Column>
              <Hr
                lineColor='charcoalGrey'
                maxWidth={[50]}
                style={{
                  opacity: 0.5,
                  margin: 0,
                  marginBottom: 16,
                  borderBottom: '2px solid #44474e'
                }}
              />
              <H6>Desenvolvedor de Web</H6>
              <Body2>Inovação e Desenvolvimento <br />João Pessoa, Brasil</Body2>
              <Button
                schema='white'
                outlined
                selected
                variant='base'
                on='onSurface'
                width={['100%']}
                maxWidth={['120px']}
                style={{
                  display: 'flex',
                  border: '1px solid rgba(0, 0, 0, 0.12)'
                }}
              >
                Aplicar
              </Button>
            </Column>
          </Div>
          <Div
            maxWidth={['100%', 422, 328, 328]}
            style={{
              marginBottom: 24
            }}
          >
            <Column>
              <Hr
                lineColor='charcoalGrey'
                maxWidth={[50]}
                style={{
                  opacity: 0.5,
                  margin: 0,
                  marginBottom: 16,
                  borderBottom: '2px solid #44474e'
                }}
              />
              <H6>Analista de Dados</H6>
              <Body2>Soluções<br /> João Pessoa, Brasil</Body2>
              <Button
                schema='white'
                outlined
                selected
                variant='base'
                on='onSurface'
                width={['100%']}
                maxWidth={['120px']}
                style={{
                  display: 'flex',
                  border: '1px solid rgba(0, 0, 0, 0.12)'
                }}
              >
                Aplicar
              </Button>
            </Column>
          </Div>
          <Div
            maxWidth={['100%', 422, 328, 328]}
          >
            <Column>
              <Hr
                lineColor='charcoalGrey'
                maxWidth={[50]}
                style={{
                  opacity: 0.5,
                  margin: 0,
                  marginBottom: 16,
                  borderBottom: '2px solid #44474e'
                }}
              />
              <H6>Analista de Marketing</H6>
              <Body2>Marketing<br /> João Pessoa, Brasil</Body2>
              <Button
                schema='white'
                outlined
                selected
                variant='base'
                on='onSurface'
                width={['100%']}
                maxWidth={['120px']}
                style={{
                  display: 'flex',
                  border: '1px solid rgba(0, 0, 0, 0.12)'
                }}
              >
                Aplicar
              </Button>
            </Column>
          </Div>
        </Row>
        <Row
          pt={[36, 46]}
          pb={[36, 46]}
        >
          <Div>
            <Button
              schema='secondary'
              color='white'
              variant='highEmphasis'
              width={['100%']}
              maxWidth={['200px']}
              style={{
                display: 'flex',
                border: 'none',
                margin: '0 auto',
                boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)'
              }}
            >
              Mais vagas
            </Button>
          </Div>
        </Row>
      </Container>
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo title='Carreiras' />
      <PageTemplate
        title='Carreiras'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
